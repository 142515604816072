import React, { Component, Suspense } from 'react';
import ConsumptionCalculator from './components/ConsumptionCalculator';
import './App.css';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-134211739-1');
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
    
  handleScroll = () => {
    var h =  document.documentElement,
        b =  document.body,
        st =  'scrollTop',
        sh =  'scrollHeight';
 
    var percent = parseInt ( (h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 100);
 
    if (percent === 25)
      ReactGA.event({ category: 'Scrolling', action: 'moreThan25%'});
    else if (percent === 50)
      ReactGA.event({ category: 'Scrolling', action: 'moreThan50%'});
    else if (percent === 75)
      ReactGA.event({ category: 'Scrolling', action: 'moreThan75%'});
    else if (percent === 90)
      ReactGA.event({ category: 'Scrolling', action: 'moreThan90%'});
  };

  render() {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <ConsumptionCalculator />
      </Suspense>
    );
  }
}

export default App;
