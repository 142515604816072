import React from 'react';

class GraphGlyphIcon extends React.Component {
  render() {
    return (
      <span className="glyphicon glyphicon-signal" onClick={() => this.props.handleGraphXAxisParameterFunction(this.props.graphXAxisParameter)} style={{"color": (this.props.currentGraphXAxisParameter === this.props.graphXAxisParameter ? "black": "lightgrey")}}></span>
    );
  }
}

export default GraphGlyphIcon;