import React, { Suspense } from 'react';
import Markdown from 'react-remarkable';
import GraphGlyphIcon from './GraphGlyphIcon';
import ReactGA from 'react-ga';
import throttle from 'react-throttle-render';
import { Trans, withTranslation } from 'react-i18next';
import i18n from 'i18next';

const ReactPlotlyChart = React.lazy(() => import('./ReactPlotlyChart'));
const ThrottledReactPlotlyChart = throttle(100)(ReactPlotlyChart);

class ConsumptionCalculator extends React.Component {

  constructor (props) {
    super(props);

    const consumptionUnitFromLocalStorage = localStorage.getItem('consumptionUnit');
    const speedUnitFromLocalStorage = localStorage.getItem('speedUnit');

    this.state = {
      stackedGraphs: true,
      consumptionUnit: consumptionUnitFromLocalStorage || "Wh/km",
      speedUnit: speedUnitFromLocalStorage || "km/h",
      graphXAxisParameter: "speedInKmPerHour",
      speedInKmPerHour: 90,
      inclineInPercent: 0.0,
      heaterAcPower: 0,
      massOfCarInKg: 1700,
      temperatureInCelcius: 15,
      relativeHumidity: 80,
      absoluteAirPressureInPascal: 101325,
      heightAboveReference: 0,
      dragCoefficient: 0.29,
      rollingCoefficient: 0.0125,
      dragReferenceAreaInSquareMeters: 2.511,
      dcToACInverterEfficiency: 0.95,
      batteryDischargeEfficiency: 0.90, // Discharge efficiency. Rouch guess inspired of https://batteryuniversity.com/learn/article/bu_808c_coulombic_and_energy_efficiency_with_the_battery
      motorEfficiencyAtMax: .96,
      motorEfficiencyAtMin: .85,
      transmissionEfficiency: .97,
      speedInKmPerHourAtMaxMotorEfficiency: 90,
      firstSliderChangeEpochTime: null,
      lastSliderChangeEpochTime: null,
      hideSliderHeader: false,
      lastGaEvent: null,
      selectedPresetId: "hyundaiKonaEv64kWh",
      // "The motor develops 291 lb.-ft. of torque distributed to the front wheels through a 7.981 axle ratio." From https://pushevs.com/2018/03/29/2019-hyundai-kona-electric-gets-estimated-epa-ratings/
      presets: [
        {id: "hyundaiKonaEv64kWh", label: "Hyundai Kona Electric 64kWh", massOfCarInKg: 1685, dragCoefficient: 0.29, rollingCoefficient: 0.0125, dragReferenceAreaInSquareMeters: 2.511, axleRatio: 7.981},
        {id: "teslaModel3LRRWD", label: "Tesla Model 3 Long Range RWD", massOfCarInKg: 1730, dragCoefficient: 0.23, rollingCoefficient: 0.0125, dragReferenceAreaInSquareMeters: 2.22},
        {id: "hyundaiIoniq", label: "Hyundai IONIQ electric", massOfCarInKg: 1435, dragCoefficient: 0.24, rollingCoefficient: 0.0125, dragReferenceAreaInSquareMeters: 2.26},
        {id: "customValue", label: this.props.t("User defined values")},
      ]
    }

    var presetFromUrl = this.state.presets.find(p => window.location.pathname.indexOf(encodeURI(p.label)) > -1);
    if (presetFromUrl) this.state.selectedPresetId = presetFromUrl.id;
      
  }

  // Send event to Google Analytics if it is not the same event as the previous or it is more than 2 seconds since sending the last event. (When pulling the sliders the handleEvent methods are called a lot)
  registerGAEventIfDifferent = (e) => {
    const now = Math.round((new Date()).getTime());
    const le = this.state.lastGaEvent;
    const lee = le && le.event;
    if(!le 
      || le.at + 2000 < now
      || (lee.category && e.category && lee.category !== e.category)
      || (lee.action && e.action && lee.action !== e.action)
      || (lee.value && e.value && lee.value !== e.value)) {

      this.setState({lastGaEvent: {at:now, event: e}}, () => {
        ReactGA.event(this.state.lastGaEvent.event);
      });
    }
  }
  getPreselectLabel = () => {
     const p = this.state.presets.find(ps => ps.id === this.state.selectedPresetId);
     return (p && p.label) || ''; 
  }
  handlePreselectChange = (event) => {
    var newPreselctId = event.target.value;
    this.setState(prevState => {

      var preset = prevState.presets.find(ps => ps.id === newPreselctId);

      window.history.pushState({lang: "en", selecedPresetId: newPreselctId}, `EVConsumption - experiment with ${preset.label} consumption`, 
        `/${i18n.languages[0]}/${preset.label}`);

      if(newPreselctId === "customValue") return {selectedPresetId: newPreselctId};

      return {
        selectedPresetId: newPreselctId, dragCoefficient: preset.dragCoefficient, rollingCoefficient: preset.rollingCoefficient,
        dragReferenceAreaInSquareMeters: preset.dragReferenceAreaInSquareMeters, massOfCarInKg: preset.massOfCarInKg
      };
    });
  }
  setCustomPreselect = () => {
    this.handlePreselectChange({target: {value: "customValue"}});
  }
  handleDragCoefficientChange = (event) => {
    this.registerGAEventIfDifferent({ category: 'Parameters', action: 'Changed drag coefficien'});
    this.setState({dragCoefficient: event.target.value});
    this.setCustomPreselect();
  }
  handleRollingCoefficientChange = (event) => {
    this.registerGAEventIfDifferent({ category: 'Parameters', action: 'Changed rolling coefficien'});
    this.setState({rollingCoefficient: event.target.value});
    this.setCustomPreselect();
  }
  handleSpeedInKmPerHourChange = (event) => {
    this.registerGAEventIfDifferent({ category: 'Parameters', action: 'Changed speed in km/h'});
    const now = Math.round((new Date()).getTime());
    this.setState({speedInKmPerHour: event.target.value, lastSliderChangeEpochTime: now, firstSliderChangeEpochTime: this.state.firstSliderChangeEpochTime || now});
  }
  handleInclineInPercentChange = (event) => {
    this.registerGAEventIfDifferent({ category: 'Parameters', action: 'Changed inclination'});
    const now = Math.round((new Date()).getTime());
    this.setState({inclineInPercent: Number(event.target.value), lastSliderChangeEpochTime: now, firstSliderChangeEpochTime: this.state.firstSliderChangeEpochTime || now});
  }
  handleHeaterAcPowerChange = (event) => {
    this.registerGAEventIfDifferent({ category: 'Parameters', action: 'Changed heaterOrACPower'});
    const now = Math.round((new Date()).getTime());
    this.setState({heaterAcPower: Number(event.target.value), lastSliderChangeEpochTime: now, firstSliderChangeEpochTime: this.state.firstSliderChangeEpochTime || now});
  }
  handleDragReferenceAreaInSquareMetersChange = (event) => {
    this.registerGAEventIfDifferent({ category: 'Parameters', action: 'Changed drag reference area'});
    this.setState({dragReferenceAreaInSquareMeters: event.target.value});
    this.setCustomPreselect();
  }
  handleTemperatureInCelciusChange = (event) => {
    this.registerGAEventIfDifferent({ category: 'Parameters', action: 'Changed temperature'});
    const now = Math.round((new Date()).getTime());
    if(event.value)
      this.setState({temperatureInCelcius: Number(event.value), lastSliderChangeEpochTime: now, firstSliderChangeEpochTime: this.state.firstSliderChangeEpochTime || now});
    else
      this.setState({temperatureInCelcius: Number(event.target.value), lastSliderChangeEpochTime: now, firstSliderChangeEpochTime: this.state.firstSliderChangeEpochTime || now});
  }
  handleRelativeHumidityChange = (event) => { 
    this.registerGAEventIfDifferent({ category: 'Parameters', action: 'Changed humidity'});
    this.setState({relativeHumidity: event.target.value});
  }
  handleAirPressureChange = (event) => {
    this.registerGAEventIfDifferent({ category: 'Parameters', action: 'Changed air pressure'});
    this.setState({absoluteAirPressureInPascal: event.target.value});
  }
  handleHeightAboveReferenceChange = (event) => {
    this.registerGAEventIfDifferent({ category: 'Parameters', action: 'Changed', label: 'Altitude'});
    const lastSliderChangeEpochTime = Math.round((new Date()).getTime());
    this.setState({heightAboveReference: Number(event.target.value), lastSliderChangeEpochTime: lastSliderChangeEpochTime});
  }
  handleMassOfCarInKgChange = (event) => {
    this.registerGAEventIfDifferent({ category: 'Parameters', action: 'Changed mass of car'});
    this.setState({massOfCarInKg: event.target.value});
    this.setCustomPreselect();
  }
  handleStackedGraphChange = (event) => {
    this.registerGAEventIfDifferent({ category: 'Graph', action: 'Changed stacked', value: event.target.checked});
    this.setState({stackedGraphs: event.target.checked});
  }
  handleGraphXAxisParameter = (parameterName) => {
    this.registerGAEventIfDifferent({ category: 'Graph', action: 'Changed x-axis', value: parameterName});
    this.setState({graphXAxisParameter: parameterName});
  }
  handleConsumptionUnitChange = (event) => {
    this.registerGAEventIfDifferent({ category: 'Graph', action: 'Changed y-axis', value: event.target.value});
    const consumptionUnit = event.target.value;
    let speedUnit = "km/h";

    if(consumptionUnit === "mi/kWh") {
      speedUnit = "mi/h";
    }
    if(consumptionUnit === "Wh/km") {
    }
    if(consumptionUnit === "Wh/mi") {
      speedUnit = "mi/h";
    }
    if(consumptionUnit === "km/kWh") {
    }
    if(consumptionUnit === "kWh/100kmh") {
    }

    localStorage.setItem('consumptionUnit', consumptionUnit)
    localStorage.setItem('speedUnit', speedUnit)
    this.setState({
      consumptionUnit: consumptionUnit,
      speedUnit: speedUnit
    });
  }

  componentDidMount = () => {
    this.interval = setInterval(() => {
      const now = Math.round((new Date()).getTime());
      const intervalToStartMs = 3000;
      const intervalToEndMs = 8000;
      const hideSliderHeader = (now > (this.state.firstSliderChangeEpochTime + intervalToStartMs) && (now < this.state.lastSliderChangeEpochTime + intervalToEndMs));
      if(hideSliderHeader && !this.state.hideSliderHeader)
      {
        var sliderSpacer = document.getElementById("sliderSpacer").offsetHeight;
        var sliderHeader = document.getElementById("sliderHeader").offsetHeight;
        window.scrollTo(window.scrollX, window.scrollY - (sliderSpacer + sliderHeader + 10))
      }

      if(hideSliderHeader !== this.state.hideSliderHeader)
        this.setState({hideSliderHeader: hideSliderHeader});

    }, 3100);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getTemperatureInKelvin() {
    return this.getTemperatureInKelvinFunction(Number(this.state.temperatureInCelcius));
  }
  getTemperatureInKelvinFunction(temperatureInCelcius) {
    return temperatureInCelcius + 273.15;
  }
  getSpeedInKmPerHour = () => {
    return Number(this.state.speedInKmPerHour);
  }
  getSpeedInMperSecond = () => {
    return this.getSpeedInMperSecondFunction(Number(this.state.speedInKmPerHour));
  }
  getSpeedInMperSecondFunction = (speedInKmPerHour) => {
    return speedInKmPerHour * 1000 / 3600;
  }
  getSpeedInUnit = (speedInKmPerHour, speedUnit) => {
    const miPerKm = 0.6213;
    return speedInKmPerHour * (speedUnit === "km/h" ? 1 : miPerKm);
  }
  getAbsoluteAirPressureInPascal = () => {
    return Number(this.state.absoluteAirPressureInPascal);
  }
  getHeightAboveReference = () => {
    return Number(this.state.heightAboveReference);
  }
  getIncline = () => {
    return Number(this.state.inclineInPercent);
  }
  getHeaterAcPower = () => {
    return Number(this.state.heaterAcPower);
  }
  getMassOfCar = () => {
    return Number(this.state.massOfCarInKg);
  }
  getTemperatureInHeightInCelsius = () => {
    return this.getTemperatureInHeightInCelsiusfunction(this.getTemperatureInKelvin(), this.getHeightAboveReference()).toFixed(1);
  }
  getTemperatureInHeightInCelsiusfunction = (temperatureInKelvin, heightAboveReference) => {
    const L = 0.0065; // Temperature lapse rate (K/m)
    const T = temperatureInKelvin - L * heightAboveReference;
    return (T - 273.15);
  }

  pressureWithDeltaHeight = (p0, T0, M, h) => {
    const R = 8.31447; // udeal universal gas constant (J/(mol*K))
    const L = 0.0065; // Temperature lapse rate (K/m)
    const g = 9.80665; // earth-surface gravitational acceleration (m/(s^2)
    const p = p0*Math.pow(1-L*h/T0, g*M/(R*L));
    return p;
  }
  getDensityOfHumidAir = () => {
    return this.getDensityOfHumidAirfunction(
      Number(this.state.temperatureInCelcius), 
      Number(this.state.relativeHumidity),
      this.getAbsoluteAirPressureInPascal(),
      this.getHeightAboveReference()
      ).toFixed(3);
  }
  getDensityOfHumidAirfunction = (temperatureInCelcius, relativeHumidity, absoluteAirPressureInPascal, heightAboveReference) => {
    // https://en.wikipedia.org/wiki/Density_of_air
    const Tcelsius = temperatureInCelcius;
    const T = this.getTemperatureInKelvinFunction(temperatureInCelcius);
    //const psat = 6.1078 * 10^(7.5 * Tcelsius / (Tcelsius + 237.3)) * 100; // Saturation vapor pressure of water (Pa)
    const psat = 6.1078 * Math.pow(10, 7.5 * Tcelsius / (Tcelsius + 237.3)) * 100; // Saturation vapor pressure of water (Pa)
    const phi = relativeHumidity / 100; // Get ration instead of %
    const pv = phi * psat; // Vapor pressure of water
    const p = absoluteAirPressureInPascal; // Absolute air pressure (Pa)
    const pd = p - pv; // Partial pressure of dry air (Pa)
    const Rd = 287.058; // Specific gas constant for dry air (J/(kg*K))
    const Rv = 461.495; // Specific gas constant for water vapor (J/(kg*K))
    const Md = 0.028964; // Molar mass of dry air (kg/mol)
    const Mv = 0.018016; // Molar mass of water vapor (kg/mol)

    //const R = 8.314; // Universal gas constant (J/(K*mol))
    //const densityOfHumidAir = pd/(Rd*T) + pv/(Rv*T);

    const h = heightAboveReference;
    const pdAtHeight = this.pressureWithDeltaHeight(pd, T, Md, h);
    const pvAtHeight = this.pressureWithDeltaHeight(pv, T, Mv, h);
    const densityOfHumidAirAdjustedForHeight = pdAtHeight/(Rd*T) + pvAtHeight/(Rv*T);

    //const avgp = densityOfHumidAir*R*T/Md; // Backcalculating to pressure - disregarding most of the ~.1% density attribution from water vapor
    //const pAtHeight = this.pressureWithDeltaHeight(avgp, T, Md, h);
    //const densityOfAvgPAdjustedForHeight = pAtHeight*Md/(R*T);

    return densityOfHumidAirAdjustedForHeight;
  }
  getDragForce = () => {
    return this.getDragForcefunction(
      Number(this.state.dragCoefficient),
      Number(this.state.dragReferenceAreaInSquareMeters),
      this.getDensityOfHumidAir(),
      this.getSpeedInMperSecond()
    ).toFixed(0);
  }
  getDragForcefunction = (dragCoefficient, dragReferenceAreaInSquareMeters, densityOfHumidAir, speedInMperSecond) => {
    // https://en.wikipedia.org/wiki/Drag_coefficient
    const cd = dragCoefficient;
    const A = dragReferenceAreaInSquareMeters;
    const Fd = 0.5 * cd * densityOfHumidAir * Math.pow(speedInMperSecond, 2) * A; 
    return Fd;
  }
  getRollingResistanceForce = () => {
    return this.getRollingResistanceForceFunction(Number(this.state.rollingCoefficient));
  }
  getRollingResistanceForceFunction = (rollingCoefficient) => {
    // https://www.sciencedirect.com/topics/engineering/rolling-resistance-coefficient
    // https://en.wikipedia.org/wiki/Rolling_resistance#Rolling_resistance_coefficient
    const g = 9.80665; // earth-surface gravitational acceleration (m/(s^2)
    const m = 1700; // Vehicle weight (kg)
    const Cr = rollingCoefficient; // rolling resistance coefficient, chose value in the middle of "Ordinary car tires on concrete interval of .01 to 015"
    const Fr = Cr*m*g;
    return Fr;
  }
  getConsumptionFromWindDrag = () => {
    return this.getConsumptionFromWindDragFunction(
      Number(this.state.dragCoefficient),
      Number(this.state.dragReferenceAreaInSquareMeters),
      this.getDensityOfHumidAir(),
      this.getSpeedInMperSecond()
    )
  }
  getConsumptionFromWindDragFunction = (dragCoefficient, dragReferenceAreaInSquareMeters, densityOfHumidAir, speedInMperSecond) => {
    // https://en.wikipedia.org/wiki/Work_(physics)
    const Fd = this.getDragForcefunction(dragCoefficient, dragReferenceAreaInSquareMeters, densityOfHumidAir, speedInMperSecond); // (N)
    const s = 1000; // displacement distance (m)
    const W = Fd * s; // Work done (J)
    const joulesPerWattHour = 3600; // (J/Wh)
    const workInWattHours = W / joulesPerWattHour; // (Wh)
    return workInWattHours;
  }
  getConsumptionFromRollingResistance = () => {
    return this.getConsumptionFromRollingResistanceFunction(this.getRollingResistanceForce());
  }
  getConsumptionFromRollingResistanceFunction = (rollingResistanceForce) => {
    // https://en.wikipedia.org/wiki/Work_(physics)
    const Fr = rollingResistanceForce;
    const s = 1000; // displacement distance (m)
    const W = Fr * s; // Work done (J)
    const joulesPerWattHour = 3600; // (J/Wh)
    const workInWattHours = W / joulesPerWattHour; // (Wh)
    return workInWattHours;
  }
  getMotorEfficiency = () => {
    return this.getMotorEfficiencyFunction(this.state.motorEfficiencyAtMax, this.state.motorEfficiencyAtMin, this.state.speedInKmPerHourAtMaxMotorEfficiency, this.state.speedInKmPerHour);
  }
  getMotorEfficiencyFunction = (motorEfficiencyAtMax, motorEfficiencyAtMin, speedInKmPerHourAtMaxMotorEfficiency, speedInKmPerHour) => {
    // Rough approximate symmetric parabolic model for motor efficiency over the range of rpms/speed
    // Going from lowest 85% to highest 96% efficiency. Highest at 90 km/h
    const efficiencyAtMax = motorEfficiencyAtMax;
    const efficiencyAtMin = motorEfficiencyAtMin;
    const speedInKmPerHourAtMaxEfficiency = speedInKmPerHourAtMaxMotorEfficiency;

    const factor = efficiencyAtMax - efficiencyAtMin;
    const motorEfficiency = -1*factor*Math.pow(speedInKmPerHour/speedInKmPerHourAtMaxEfficiency-1, 2)+efficiencyAtMax;
    //return -0.11(x/90-1)^2+.96
    return motorEfficiency;
  }
  getConsumptionFromMotor = () => {
    return this.getConsumptionFromMotorFunction(this.getConsumptionFromRollingResistance(), this.getConsumptionFromWindDrag(), this.getConsumptionFromTransmission(), this.getMotorEfficiency());
  }
  getConsumptionFromMotorFunction = (consumptionFromRollingResistance, consumptionFromWindDrag, consumptionFromTransmission, motorEfficiency) => {
    const consumptionFromDragTyresAndTransmission = consumptionFromRollingResistance + consumptionFromWindDrag + consumptionFromTransmission;
    const consumptionFromMotor = consumptionFromDragTyresAndTransmission / motorEfficiency - consumptionFromDragTyresAndTransmission;
    return consumptionFromMotor; // (Wh/km)
  }

  getConsumptionFromTransmission = () => {
    return this.getConsumptionFromTransmissionFunction(this.getConsumptionFromRollingResistance(), this.getConsumptionFromWindDrag(), this.state.transmissionEfficiency);
  }
  getConsumptionFromTransmissionFunction = (consumptionFromRollingResistance, consumptionFromWindDrag, transmissionEfficiency) => {
    const consumptionFromDragAndTyres = consumptionFromRollingResistance + consumptionFromWindDrag;
    const consumptionFromTransmission = consumptionFromDragAndTyres / transmissionEfficiency - consumptionFromDragAndTyres;
    return consumptionFromTransmission; // (Wh/km)
  }

  getConsumptionFromDCToACInverter = () => {
    return this.getConsumptionFromDCToACInverterFunction(this.getConsumptionFromRollingResistance(), this.getConsumptionFromWindDrag(), this.getConsumptionFromMotor());
  }
  getConsumptionFromDCToACInverterFunction = (consumptionFromRollingResistance, consumptionFromWindDrag, consumptionFromMotor) => {
    const consumptionFromDragTyresAndMotor = consumptionFromRollingResistance 
      + consumptionFromWindDrag
      + consumptionFromMotor;
    const dcToACInverterEfficiency = this.state.dcToACInverterEfficiency; // 0.95
    const consumptionFromDCToAcInverter = consumptionFromDragTyresAndMotor / dcToACInverterEfficiency - consumptionFromDragTyresAndMotor;
    return consumptionFromDCToAcInverter; // (Wh/km)
  }
  getConsumptionFromBatteryDischargeHeat = () => {
    return this.getConsumptionFromBatteryDischargeHeatFunction(this.getConsumptionFromRollingResistance(), this.getConsumptionFromWindDrag(), this.getConsumptionFromMotor(), this.getConsumptionFromDCToACInverter());
  }
  getConsumptionFromBatteryDischargeHeatFunction = (consumptionFromRollingResistance, consumptionFromWindDrag, consumptionFromMotor, consumptionFromDCToAcInverter) => {
    const consumptionFromDragTyresMotorAndInverter = consumptionFromRollingResistance 
      + consumptionFromWindDrag
      + consumptionFromMotor
      + consumptionFromDCToAcInverter;
    const batteryDischargeEfficiency = this.state.batteryDischargeEfficiency; //0.90; // Discharge efficiency. Rouch guess inspired of https://batteryuniversity.com/learn/article/bu_808c_coulombic_and_energy_efficiency_with_the_battery
    const consumptionFromBatteryDischarge = consumptionFromDragTyresMotorAndInverter / batteryDischargeEfficiency - consumptionFromDragTyresMotorAndInverter;
    return consumptionFromBatteryDischarge; // (Wh/km)
  }

  getConsumptionFromIncline = () =>  {
    return this.getConsumptionFromInclineFunction(this.getIncline(), this.getMassOfCar());
  }
  getConsumptionFromInclineFunction = (inclineInPercent, massOfCarInKg) =>  {
    // Calculating consumption per 1 km
    const  standardAccelerationDueToGravity = 9.8; // (m/(s^2)i)
    const heightGainInMPerKm = inclineInPercent / 100 * 1000; // (m)
    const potentialEnergyPerKm = heightGainInMPerKm * massOfCarInKg * standardAccelerationDueToGravity;// (J)
    const joulesPerWattHour = 3600;
    const consumptionFromIncline = potentialEnergyPerKm / joulesPerWattHour; //(Wh/km)
    return consumptionFromIncline; // (Wh/km)
  }

  getConsumptionFromHeaterAcPower = () => {
    return this.getConsumptionFromHeaterAcPowerFunction(this.getHeaterAcPower(), this.getSpeedInMperSecond());
  }
  getConsumptionFromHeaterAcPowerFunction = (heaterAcPower, speedInMperSecond) => {
    const secondsForOneKm = 1000 / speedInMperSecond; //(s)
    const joulesForOneKm = heaterAcPower * secondsForOneKm; //(J/km)
    const joulesPerWattHour = 3600; // (J/Wh)
    const consumption = joulesForOneKm / joulesPerWattHour// (Wh/km);
    return consumption;
  }

  getTotalConsumption = () => {
    return this.getTotalConsumptionFunction(this.getConsumptionFromRollingResistance(), this.getConsumptionFromWindDrag(), this.getConsumptionFromTransmission(), this.getConsumptionFromMotor(), this.getConsumptionFromDCToACInverter(), this.getConsumptionFromBatteryDischargeHeat(), this.getConsumptionFromIncline(), this.getConsumptionFromHeaterAcPower());
  }
  getTotalConsumptionInChoseUnit = () => {
    const c = this.getConsumptionInUnit(this.getTotalConsumption(), this.state.consumptionUnit);
    for(let i = 0; i < 5; i++) {
      const value = c.toFixed(i);
      if(value.replace(".","").length > 2) return `${value} ${this.state.consumptionUnit}`;
    }
  }
  getTotalConsumptionFunction = (consumptionFromRollingResistance, consumptionFromWindDrag, consumptionFromTransmission, consumptionFromMotor, consumptionFromDCToAcInverter, consumptionFromBatteryDischarge, consumptionFromIncline, consumptionFromHeaterAcPower) => {
    return consumptionFromRollingResistance 
      + consumptionFromWindDrag
      + consumptionFromTransmission
      + consumptionFromMotor
      + consumptionFromDCToAcInverter
      + consumptionFromBatteryDischarge
      + consumptionFromIncline
      + consumptionFromHeaterAcPower;
  }

  getConsumptionInUnit = (WhPerKm, consumptionUnit) => {
    if(WhPerKm === 0) return 0;
    if(WhPerKm === 0) WhPerKm = 0.000001;
    const miPerKm = 0.6213;
    if(consumptionUnit === "mi/kWh") {
      return miPerKm / WhPerKm * 1000;
    }
    if(consumptionUnit === "Wh/km") {
      return WhPerKm;
    }
    if(consumptionUnit === "Wh/mi") {
      return WhPerKm / miPerKm;
    }
    if(consumptionUnit === "km/kWh") {
      return  1 / WhPerKm * 1000;
    }
    if(consumptionUnit === "kWh/100kmh") {
      return WhPerKm / 10;
    }
  }

  getGraphData = () => {
    let plotlyData = {x: [], totalConsumption:[], consumptionFromWindDrag: [], consumptionFromRollingResistance: [], consumptionFromTransmission: [], consumptionFromMotor: [], consumptionFromDCToAcInverter: [], consumptionFromHeaterAcPower: [], consumptionFromBatteryDischarge: [], consumptionFromIncline: []};
    let consumptionRangeMax = null;
    let consumptionRangeMin = 0;

    const xaxis = this.state.graphXAxisParameter;
    let fromX = 20;
    let toX = 177;
    let xAxisUnit = this.state.speedUnit;
    if(xaxis === "speedInKmPerHour") {
      fromX = 20;
      toX = 177;
      xAxisUnit = this.state.speedUnit
    } else if (xaxis === "temperatureInCelcius") {
      fromX = -20;
      toX = 40;
      xAxisUnit = "temperature in Celsius";
    } else if (xaxis === "heightAboveReference") {
      fromX = 0;
      toX = 3000;
      xAxisUnit = "altitude in metres";
    } else if (xaxis === "inclineInPercent") {
      fromX = -15;
      toX = 15;
      xAxisUnit = "percent incline";
    } else if (xaxis === "heaterAcPower") {
      fromX = 0;
      toX = 7000;
      xAxisUnit = "heater/AC power in Watts";
    }
    const noOfCalculationPointsOnXaxis = 150;
    let xAxisStep = (toX - fromX) / noOfCalculationPointsOnXaxis;

    const speedInKmPerHourFromState = this.state.speedInKmPerHour;
    const speedInMperSecondFromState =  this.getSpeedInMperSecondFunction(speedInKmPerHourFromState);
    const temperatureInCelciusFromState = Number(this.state.temperatureInCelcius);
    const heightAboveReferenceFromState = this.getHeightAboveReference();
    const inclineFromState = this.getIncline();
    const heaterAcPowerFromState = this.getHeaterAcPower();

    for(let s = fromX; s < toX; s += xAxisStep) {
      const speedInKmPerHour = ((xaxis === "speedInKmPerHour") ? s : speedInKmPerHourFromState);
      const temperatureInCelcius = ((xaxis === "temperatureInCelcius") ? s : temperatureInCelciusFromState);
      const heightAboveReference = ((xaxis === "heightAboveReference") ? s : heightAboveReferenceFromState);
      const incline = ((xaxis === "inclineInPercent") ? s : inclineFromState);
      const heaterAcPower = ((xaxis === "heaterAcPower") ? s : heaterAcPowerFromState);

      const speedInMperSecond = ((xaxis === "speedInKmPerHour") ? this.getSpeedInMperSecondFunction(speedInKmPerHour) : speedInMperSecondFromState);

      const consumptionFromWindDrag = this.getConsumptionFromWindDragFunction( Number(this.state.dragCoefficient), Number(this.state.dragReferenceAreaInSquareMeters), this.getDensityOfHumidAirfunction( temperatureInCelcius, Number(this.state.relativeHumidity), this.getAbsoluteAirPressureInPascal(), heightAboveReference), speedInMperSecond);
      const consumptionFromRollingResistance = this.getConsumptionFromRollingResistance();
      const consumptionFromTransmission = this.getConsumptionFromTransmissionFunction(consumptionFromRollingResistance, consumptionFromWindDrag, Number(this.state.transmissionEfficiency));
      const consumptionFromMotor = this.getConsumptionFromMotorFunction(consumptionFromRollingResistance, consumptionFromWindDrag, consumptionFromTransmission, this.getMotorEfficiencyFunction(this.state.motorEfficiencyAtMax, this.state.motorEfficiencyAtMin, this.state.speedInKmPerHourAtMaxMotorEfficiency, speedInKmPerHour));
      const consumptionFromDCToAcInverter = this.getConsumptionFromDCToACInverterFunction(consumptionFromRollingResistance, consumptionFromWindDrag, consumptionFromMotor);
      const consumptionFromHeaterAcPower = this.getConsumptionFromHeaterAcPowerFunction(heaterAcPower, speedInMperSecond);
      const consumptionFromBatteryDischarge = this.getConsumptionFromBatteryDischargeHeatFunction(consumptionFromRollingResistance, consumptionFromWindDrag, consumptionFromMotor, consumptionFromDCToAcInverter);
      const consumptionFromIncline = this.getConsumptionFromInclineFunction(incline, this.getMassOfCar());
      const consumptionInWhPerKm = this.getTotalConsumptionFunction( consumptionFromRollingResistance, consumptionFromWindDrag, consumptionFromTransmission, consumptionFromMotor, 
      consumptionFromDCToAcInverter, consumptionFromBatteryDischarge, consumptionFromIncline, consumptionFromHeaterAcPower);

      const consumption = this.getConsumptionInUnit(consumptionInWhPerKm, this.state.consumptionUnit);
      const speedInChosenUnit = this.getSpeedInUnit(speedInKmPerHour, this.state.speedUnit);

      if(xaxis === "speedInKmPerHour") plotlyData.x.push(speedInChosenUnit);
      else if (xaxis === "temperatureInCelcius") plotlyData.x.push(temperatureInCelcius);
      else if (xaxis === "heightAboveReference") plotlyData.x.push(heightAboveReference);
      else if (xaxis === "inclineInPercent") plotlyData.x.push(incline);
      else if (xaxis === "heaterAcPower") plotlyData.x.push(heaterAcPower);

      plotlyData.totalConsumption.push(consumption);
      plotlyData.consumptionFromWindDrag.push(this.getConsumptionInUnit(consumptionFromWindDrag, this.state.consumptionUnit));
      plotlyData.consumptionFromRollingResistance.push(this.getConsumptionInUnit(consumptionFromRollingResistance, this.state.consumptionUnit));
      plotlyData.consumptionFromTransmission.push(this.getConsumptionInUnit(consumptionFromTransmission, this.state.consumptionUnit));
      plotlyData.consumptionFromMotor.push(this.getConsumptionInUnit(consumptionFromMotor, this.state.consumptionUnit));
      plotlyData.consumptionFromDCToAcInverter.push(this.getConsumptionInUnit(consumptionFromDCToAcInverter, this.state.consumptionUnit));
      plotlyData.consumptionFromHeaterAcPower.push(this.getConsumptionInUnit(consumptionFromHeaterAcPower, this.state.consumptionUnit));
      plotlyData.consumptionFromBatteryDischarge.push(this.getConsumptionInUnit(consumptionFromBatteryDischarge, this.state.consumptionUnit));
      plotlyData.consumptionFromIncline.push(this.getConsumptionInUnit(consumptionFromIncline, this.state.consumptionUnit));


      if(consumptionRangeMax == null || consumption > Number(consumptionRangeMax)) consumptionRangeMax = consumption;
      if(consumption < 0 && consumption < consumptionRangeMin) consumptionRangeMin = Number(consumption);
    }

    // Calculating the y-axis max in a way, so it will jump in 1.4 factor proportions
    const baseLog = 1.4;
    const maxConsumptionNumberOfZeros = Math.floor(Math.log(consumptionRangeMax) / Math.log(baseLog));
    const consumptionRangeMaxAdjustedUpToClosetLogBasePower = Math.pow(baseLog, maxConsumptionNumberOfZeros +1);
    if(consumptionRangeMin < 0) consumptionRangeMin = consumptionRangeMin + consumptionRangeMin * 0.1;

    debugger;
    return {stackedGraphs: this.state.stackedGraphs, xAxisUnit: xAxisUnit, consumptionUnit: this.state.consumptionUnit
      , consumptionRangeMin: consumptionRangeMin
      , consumptionRangeMax: consumptionRangeMaxAdjustedUpToClosetLogBasePower, ...plotlyData};
  }

  render() {
    return (

<div id="reactContent">
  <div className="container">
    <h2 id="top"><Trans i18nKey="siteTitle">EV Lab - Experimenting with consumption</Trans>&nbsp;&nbsp;<a href="#info"><span alt="Read more" className="glyphicon glyphicon-info-sign" style={{"color": "darkgrey"}}></span></a></h2>
    <h3> - <Trans i18nKey="siteTitleSubtext">preset for </Trans>&nbsp;
      <select value={this.state.selectedPresetId} onChange={this.handlePreselectChange}>
        {this.state.presets.map((p) => <option key={p.id} value={p.id}>{p.label}</option>)}
      </select>
    </h3>
    <p>&nbsp;</p>
  </div>
  <div className="container" id="theChart">
    <Suspense fallback={<div>Loading...</div>}>
      <ThrottledReactPlotlyChart dataContainer={this.getGraphData()} />
    </Suspense>
  </div>

  {this.state.hideSliderHeader ? '' : (
  <div className="container" id="sliderSpacer">
      <p>&nbsp;</p>
  </div>)}

  <div className="container">
    {this.state.hideSliderHeader ? '' : (
    <h4 id="sliderHeader"><Trans i18nKey="slidersTitleText">Experiment by adjusting your Temperature, Height, Incline or HeaterAC power</Trans>&nbsp;&nbsp;<a href="#info"><span alt="Read more" className="glyphicon glyphicon-info-sign" style={{"color": "darkgrey"}}></span></a></h4>
    )}
    <table className="table" width="100%">
      <tbody>
        <tr>
          <td width="150em"><GraphGlyphIcon handleGraphXAxisParameterFunction={this.handleGraphXAxisParameter} graphXAxisParameter={"temperatureInCelcius"} currentGraphXAxisParameter={this.state.graphXAxisParameter} /> <Trans>Temperature</Trans></td>
          <td width="70%"><input type="range" min="-20" max="40" id="nativeSliderTemp" className="slider" value={this.state.temperatureInCelcius} onChange={this.handleTemperatureInCelciusChange}/></td>
          <td width="width: 3em" className="text-nowrap">({this.state.temperatureInCelcius.toString().padStart(3, " ")}&deg;)</td>
        </tr>
        <tr>
          <td><GraphGlyphIcon handleGraphXAxisParameterFunction={this.handleGraphXAxisParameter} graphXAxisParameter={"heightAboveReference"} currentGraphXAxisParameter={this.state.graphXAxisParameter} /> <Trans>Altitude</Trans></td>
          <td><input type="range" min="0" max="3000" id="nativeSliderHeight" className="slider" value={this.state.heightAboveReference} onChange={this.handleHeightAboveReferenceChange}/></td>
          <td className="text-nowrap">({this.state.heightAboveReference.toString().padStart(4, " ")} m)</td>
        </tr>
        <tr>
          <td><GraphGlyphIcon handleGraphXAxisParameterFunction={this.handleGraphXAxisParameter} graphXAxisParameter={"inclineInPercent"} currentGraphXAxisParameter={this.state.graphXAxisParameter} /> <Trans>Incline</Trans></td>
          <td><input type="range" min="-15" max="15" id="nativeSliderIncline" className="slider" value={this.state.inclineInPercent} onChange={this.handleInclineInPercentChange} /></td>
          <td className="text-nowrap">({this.getIncline().toFixed(1).toString().padStart(5, " ")} %)</td>
        </tr>
        <tr>
          <td><GraphGlyphIcon handleGraphXAxisParameterFunction={this.handleGraphXAxisParameter} graphXAxisParameter={"heaterAcPower"} currentGraphXAxisParameter={this.state.graphXAxisParameter} /> <Trans>Heater/AC power</Trans></td>
          <td><input type="range" min="0" max="7000" id="nativeSliderHeaterAcPower" className="slider" value={this.state.heaterAcPower} onChange={this.handleHeaterAcPowerChange}  /></td>
          <td className="text-nowrap">({this.getHeaterAcPower().toFixed(0).toString().padStart(4, " ")} W)</td>
        </tr>
        <tr>
          <td><GraphGlyphIcon handleGraphXAxisParameterFunction={this.handleGraphXAxisParameter} graphXAxisParameter={"speedInKmPerHour"} currentGraphXAxisParameter={this.state.graphXAxisParameter} /> <Trans>Speed</Trans></td>
          <td><input type="range" min="0" max="180" id="nativeSliderSpeedInKmPerHour" className="slider" value={this.state.speedInKmPerHour} onChange={this.handleSpeedInKmPerHourChange} /></td>
          <td className="text-nowrap">({this.getSpeedInKmPerHour().toFixed(0).toString().padStart(3, " ")} km/h)</td>
        </tr>
        <tr><td colSpan={3}>&nbsp;</td></tr>
        <tr>
          <td colSpan={3}>
            <Trans>Preferred unit for consumption</Trans>:&nbsp;&nbsp; 
            <select value={this.state.consumptionUnit} onChange={this.handleConsumptionUnitChange}>
              <option value="mi/kWh">mi/kWh</option>
              <option value="km/kWh">km/kWh</option>
              <option value="Wh/km">Wh/km</option>
              <option value="Wh/mi">Wh/mi</option>
              <option value="kWh/100kmh">kWh/100kmh</option>
            </select>
          </td>
        </tr>
        <tr>
          <td colSpan={3}>
            {(["km/kWh", "mi/kWh"].indexOf(this.state.consumptionUnit) === -1) ?
            <span className="text-nowrap"><Trans>Stacked graphs</Trans>&nbsp;&nbsp;<input type="checkbox" checked={this.state.stackedGraphs} onChange={this.handleStackedGraphChange} /> </span> : ''}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div className="container">
    <div className="panel panel-default">
      <div className="panel-body">
        <h5><Trans>Estimated consumption</Trans> <b>{this.getTotalConsumptionInChoseUnit()}</b></h5>
        <Trans>For a</Trans> {this.getPreselectLabel()} <Trans i18nKey="atCurrentSpecs" values={{speed: this.getSpeedInKmPerHour().toFixed(0).toString().padStart(3, " "), temp: this.state.temperatureInCelcius.toString().padStart(3, " "), height: this.state.heightAboveReference.toString().padStart(4, " "), incline: this.getIncline().toFixed(1).toString().padStart(5, " "), heater: this.getHeaterAcPower().toFixed(0).toString().padStart(4, " ")}} >at current specs</Trans>
      </div>
    </div>
  </div>

  <div className="jumbotron">
    <div className="container">
      <a href="#top"><Trans>Back to top</Trans></a>
      <h2 id="info"><Trans i18nKey="infoTop">EV Lab - Experimenting with consumption by temperature, speed, humidity and height</Trans></h2>
      <Markdown source={this.props.t("infoSection1", {motorEfficiencyAtMin: (this.state.motorEfficiencyAtMin*100), 
        motorEfficiencyAtMax: (this.state.motorEfficiencyAtMax*100),
        transmissionLoss: ((1-this.state.transmissionEfficiency)*100).toFixed(0),
        speedInKmPerHourAtMaxMotorEfficiency: this.state.speedInKmPerHourAtMaxMotorEfficiency,
        speedInKmPerHourAtMaxMotorEfficiencyTimes2: this.state.speedInKmPerHourAtMaxMotorEfficiency*2,
        dcToACInverterEfficiency: this.state.dcToACInverterEfficiency*100,
        batteryDischargeEfficiency: this.state.batteryDischargeEfficiency*100,
        })}/>
    </div>
  </div>

  <div className="container">
    <p>&nbsp;</p>
    <h4><Trans i18nKey="varsConstAndCalcVal">Variables, constants and calculated variables</Trans></h4>
    <table className="table table-striped table-sm">
      <tbody>
        <tr>
          <th colSpan={3}><Trans>Constants for the model of car</Trans></th>
        </tr>
        <tr>
          <td><Trans>Weight of car</Trans></td>
          <td><input value={this.state.massOfCarInKg} onChange={this.handleMassOfCarInKgChange} type="text" className="form-control" aria-label="Speed" /></td>
          <td>kg</td>
        </tr>
        <tr>
          <td><Trans>Drag coefficient</Trans></td>
          <td colSpan={2}><input value={this.state.dragCoefficient} onChange={this.handleDragCoefficientChange} type="text" className="form-control" aria-label="Drag coefficient" /></td>
        </tr>
        <tr>
          <td><Trans i18nKey="tyreRollingResistance">Tyre rolling resistance coefficient (Normal 0.010 to 0.15)</Trans></td>
          <td colSpan={2}><input value={this.state.rollingCoefficient} onChange={this.handleRollingCoefficientChange} type="text" className="form-control" aria-label="Rolling resistance coefficient" /></td>
        </tr>
        <tr>
          <td><Trans>Drag reference area</Trans></td>
          <td><input value={this.state.dragReferenceAreaInSquareMeters} onChange={this.handleDragReferenceAreaInSquareMetersChange} type="text" className="form-control" aria-label="Speed" /></td>
          <td>m&sup2;</td>
        </tr>
        <tr>
          <td colSpan={3}>&nbsp;</td>
        </tr>

        <tr><th colSpan={3}><Trans>Variables</Trans></th></tr>
        <tr>
          <td><Trans>Speed</Trans></td>
          <td><input value={this.state.speedInKmPerHour} onChange={this.handleSpeedInKmPerHourChange} type="text" className="form-control" aria-label="Speed" /></td>
          <td>km/h</td>
        </tr>
        <tr>
          <td><Trans>Incline</Trans></td>
          <td><input value={this.state.inclineInPercent} onChange={this.handleInclineInPercentChange} type="text" className="form-control" aria-label="Speed" /></td>
          <td>%</td>
        </tr>
        <tr>
          <td><Trans>Heater/AC power</Trans></td>
          <td><input value={this.state.heaterAcPower} onChange={this.handleHeaterAcPowerChange} type="text" className="form-control" aria-label="Speed" /></td>
          <td>W</td>
        </tr>
        <tr>
          <td colSpan={3}>&nbsp;</td>
        </tr>
        <tr>
          <th colSpan={3}><Trans i18nKey="valuesAsRefHeight">The values below should be at reference height. F.ex. sea level</Trans></th>
        </tr>
        <tr>
          <td><Trans>Temperature</Trans></td>
          <td><input value={this.state.temperatureInCelcius} onChange={this.handleTemperatureInCelciusChange} id="temperatureInCelsius" type="text" className="form-control" aria-label="Speed" aria-describedby="basic-addon3" /></td>
          <td>&deg;C</td>
        </tr>
        <tr>
          <td><Trans>Relative humidity</Trans></td>
          <td><input value={this.state.relativeHumidity} onChange={this.handleRelativeHumidityChange} type="text" className="form-control" aria-label="Speed" /></td>
          <td>%</td>
        </tr>
        <tr>
          <td><Trans>Air pressure</Trans></td>
          <td><input value={this.state.absoluteAirPressureInPascal} onChange={this.handleAirPressureChange} type="text" className="form-control" aria-label="Speed" /></td>
          <td>Pa</td>
        </tr>
        <tr>
          <td colSpan={3}>&nbsp;</td>
        </tr>
        <tr>
          <th colSpan={3}><Trans i18nKey="currentAltitudeForValuesBelow">Current altitude above your reference height for the values above</Trans></th>
        </tr>
        <tr>
          <td><Trans>Altitude</Trans></td>
          <td><input value={this.state.heightAboveReference} onChange={this.handleHeightAboveReferenceChange} id="heightAboveReference" type="text" className="form-control" aria-label="Speed" aria-describedby="basic-addon3" /></td>
          <td>m</td>
        </tr>
        <tr>
          <td colSpan={3}>&nbsp;</td>
        </tr>
        <tr>
          <th colSpan={3}><Trans>Calculated variables</Trans></th>
        </tr>
        <tr>
          <td><Trans i18nKey="consumptionTotal">Consumption (Total)</Trans></td>
          <td><b>{this.getTotalConsumption().toFixed(0)}</b></td>
          <td>Wh/km</td>
        </tr>
        <tr>
          <td><Trans i18nKey="consumptionIncline">Consumption from incline</Trans></td>
          <td>{this.getConsumptionFromIncline().toFixed(1)}</td>
          <td>Wh/km</td>
        </tr>
        <tr>
          <td><Trans i18nKey="consumptionBattery">Consumption from battery discharge heat</Trans></td>
          <td>{this.getConsumptionFromBatteryDischargeHeat().toFixed(1)}</td>
          <td>Wh/km</td>
        </tr>
        <tr>
          <td><Trans i18nKey="consumptionAc2DC">Consumption from DC to AC inverter for motor power</Trans></td>
          <td>{this.getConsumptionFromDCToACInverter().toFixed(1)}</td>
          <td>Wh/km</td>
        </tr>
        <tr>
          <td><Trans i18nKey="consumptionMotor">Consumption from motor</Trans></td>
          <td>{this.getConsumptionFromMotor().toFixed(1)}</td>
          <td>Wh/km</td>
        </tr>
        <tr>
          <td><Trans i18nKey="consumptionTransmission">Consumption from transmission</Trans></td>
          <td>{this.getConsumptionFromTransmission().toFixed(1)}</td>
          <td>Wh/km</td>
        </tr>
        <tr>
          <td><Trans i18nKey="consumptionWindDrag">Consumption from wind drag</Trans></td>
          <td>{this.getConsumptionFromWindDrag().toFixed(0)}</td>
          <td>Wh/km</td>
        </tr>
        <tr>
          <td><Trans i18nKey="consumptionTyreDrag">Consumption from tyre drag</Trans></td>
          <td>{this.getConsumptionFromRollingResistance().toFixed(0)}</td>
          <td>Wh/km</td>
        </tr>
        <tr>
          <td><Trans>Wind drag force</Trans></td>
          <td>{this.getDragForce()}</td>
          <td>N</td>
        </tr>
        <tr>
          <td><Trans>Air density</Trans></td>
          <td>{this.getDensityOfHumidAir()}</td>
          <td>kg/m&sup3;</td>
        </tr>
        <tr>
          <td><Trans>Speed</Trans></td>
          <td>{this.getSpeedInMperSecond().toFixed(1)}</td>
          <td>m/s</td>
        </tr>
        <tr>
          <td><Trans>Temperature at reference height</Trans></td>
          <td>{this.getTemperatureInKelvin()}</td>
          <td>K</td>
        </tr>
        <tr>
          <td><Trans>Temperature at altitude</Trans></td>
          <td>{this.getTemperatureInHeightInCelsius()}</td>
          <td>&deg;C</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div className="container">
    <hr />
    <footer>
      <p><Trans i18nKey="footerText">Made in February 2019</Trans></p>
    </footer>
  </div>
</div>
    );
  }
}

export default withTranslation('common')(ConsumptionCalculator);