import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './i18n/locales/en/translation.json';
import da from './i18n/locales/da/translation.da.json';
import es from './i18n/locales/es/translation.es.json';
import de from './i18n/locales/de/translation.de.json';
import zn from './i18n/locales/zn/translation.zn.json';


i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance

  .init({
    resources: {
        da: { common: da['da'], },
        en: { common: en['en'], },
        es: { common: es['es'], },
        de: { common: de['de'], },
        zn: { common: zn['zn'], },
    },

    load: 'languageOnly',
    fallbackLng: 'en',
    //partialBundledLanguages: true,
    debug: true,
    //returnObjects: true,
    joinArrays: "",
    //updateMissing: true,
    //saveMissingTo: 'all',
    detection: {
      order: ['path', 'navigator']
    },
    interpolation: {
      //escapeValue: false, // not needed for react!!
    },

    ns: ['common'],

    defaultNS: 'common',

    // react i18next special options (optional)
    /*
    react: {
      bindI18n: 'languageChanged loaded'
    }
    */
  });
  //.changeLanguage('en', (err, t) => {
    //if (err) return console.log('something went wrong loading', err);
  //});


export default i18n;